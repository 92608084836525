<template>
  <TaskDatePickerPopover
    ref="dateRef"
    :start-date="task.startDate"
    :due-date="task.dueDate"
    class="w-full h-full"
    :disabled="disabled"
    :ui="{
      trigger: `flex h-full items-center ${disabled ? '!cursor-not-allowed' : ''}`,
    }"
    @click.exact.stop.prevent
    @change="handleDateChange"
  >
    <UButton
      size="2xs"
      variant="soft"
      color="white"
      class="text-gray-900 w-full h-full py-0"
      :disabled="disabled"
      @click="emit('click')"
    >
      <TaskDatesFormat
        v-if="task.startDate || task.dueDate"
        class="text-start leading-4 whitespace-pre-wrap text-wrap flex flex-wrap items-center h-full font-normal"
        :start="task.startDate"
        :end="task.dueDate"
        :format="{
          time: ''
        }"
      />
      <UButton
        v-else
        variant="ghost"
        color="white"
        class="rounded-full w-fit px-0 hover:bg-transparent"
        icon="i-heroicons-calendar"
        size="2xs"
        :disabled="disabled"
      />
    </UButton>
  </TaskDatePickerPopover>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['set-callback', 'click'])

const { checkedTasks } = useListViewSharedState()
const { updateTask, updateTasks } = useUpdateTask()

const dateRef = ref()

const handleDateChange = (dates: Pick<TaskItem, 'startDate' | 'dueDate'>) => {
  if (checkedTasks.value.has(props.task.id)) {
    return updateTasks(Array.from(checkedTasks.value), dates)
  }

  updateTask(props.task.id, dates)
}

const handleOpen = () => {
  dateRef.value?.open()
}

onMounted(() => {
  emit('set-callback', {
    open: handleOpen,
    delete: () => {
      handleDateChange({ startDate: '', dueDate: '' })
    }
  })
})

defineExpose({
  focus: () => handleOpen,
})
</script>
